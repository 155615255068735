import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Content from "../components/layout/Content";
import Input from "../components/Input";
import Arrow from "../icons/Arrow";
import styles from "../styles/CreateChallenge.module.css";

const API_URL = process.env.REACT_APP_API_BASEURL;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

function CreateStore() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [name, setName] = React.useState("");
  const [isValidName, setIsValidName] = React.useState("IDLE");

  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  function validateTextField({ value, setValidationStatus }) {
    if (value && value.length > 1) {
      setValidationStatus("TRUE");
      return true;
    } else {
      setValidationStatus(value === "" ? "IDLE" : "ERROR");
      return false;
    }
  }

  function validateForm() {
    const validName = validateTextField({
      value: name,
      setValidationStatus: setIsValidName,
    });

    if (validName !== true) {
      if (validName !== true) {
        setIsValidName("ERROR");
      }
      return false;
    }
    return true;
  }

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const isValidForm = validateForm();

    if (isValidForm) {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });

        const { data } = await axios.post(
          `${API_URL}/stores`,
          {
            name,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (data && data.hasOwnProperty("id")) {
          console.log(`Challenge ${data?.id} was successfully crerated`);
          navigate(`/stores`);
        } else {
          console.log("Something went wrong while creating challenge");
        }
      } catch (error) {
        console.log(error);
        console.log("Something went wrong while creating challenge");
      }
    }
    setLoading(false);
  }

  return (
    isAuthenticated && (
      <div>
        <div className={styles.subHeader}>
          <Content>
            <Link className={styles.backButton} to="/stores">
              <Arrow
                className={styles.backIcon}
                direction="left"
                color="#cf2e05"
              />
              Tillbaka
            </Link>
          </Content>
        </div>
        <section className={styles.createChallenge}>
          <Content>
            <h1 className={styles.heading}>Skapa affär</h1>
            <div className={styles.content}>
              <form onSubmit={onSubmit}>
                <div>
                  <div>
                    <label className={styles.labelHeading} htmlFor="name">
                      Namn
                    </label>
                    <Input
                      isValid={isValidName}
                      onBlur={() =>
                        validateTextField({
                          value: name,
                          setValidationStatus: setIsValidName,
                        })
                      }
                      id="name"
                      type="text"
                      placeholder="Namn på affären"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>

                <div className={styles.action}>
                  <button
                    className={styles.createButton}
                    disabled={loading}
                    type="submit"
                  >
                    Skapa affär
                  </button>
                </div>
              </form>
            </div>
          </Content>
        </section>
      </div>
    )
  );
}
export default CreateStore;
