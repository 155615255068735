import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Header from "../components/layout/Header";
import Content from "../components/layout/Content";
import styles from "../styles/Challenges.module.css";

const API_URL = process.env.REACT_APP_API_BASEURL;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

function Challenges() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [challenges, setChallenges] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    let componentMounted = true;

    async function getChallenges() {
      setFetching(true);

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });

        if (accessToken) {
          const { data } = await axios.get(`${API_URL}/stores`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (data && data.stores?.length > 0 && componentMounted) {
            setChallenges(data.stores);
          } else {
            setChallenges([]);
          }
        }
      } catch (error) {
        console.log(error);
        setChallenges([]);
      }
      setFetching(false);
    }

    getChallenges();

    return () => {
      componentMounted = false;
    };
  }, [getAccessTokenSilently]);

  return (
    isAuthenticated && (
      <div>
        <Header>
          <Link className={styles.headerButton} to="/stores/create">
            Skapa ny affär
          </Link>
        </Header>
        <section className={styles.challenges}>
          {fetching === true ? (
            <div className={styles.loading}>
              <Content>
                <p className={styles.loadingText}>Hämtar tävlingar…</p>
              </Content>
            </div>
          ) : challenges.length > 0 ? (
            <Content>
              <h1 className={styles.heading}>Affärer</h1>
              <ul className={styles.list}>
                {challenges.map((challenge, index) => (
                  <li className={styles.item} key={index}>
                    <Link
                      className={styles.link}
                      to={`/stores/${challenge.id}`}
                    >
                      <span>{challenge.name}</span>
                      <span className={styles.itemPeriod}>
                        {challenge.dateFrom} – {challenge.dateTo}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </Content>
          ) : (
            <div className={styles.noChallenges}>
              <Content>
                <p className={styles.noChallengesText}>
                  Skapa din första affär
                </p>
                <Link className={styles.noChallengesLink} to="/stores/create">
                  Ny affär
                </Link>
              </Content>
            </div>
          )}
        </section>
      </div>
    )
  );
}
export default Challenges;
