import React from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Content from "../components/layout/Content";
import Arrow from "../icons/Arrow";
import styles from "../styles/Challenge.module.css";

const API_URL = process.env.REACT_APP_API_BASEURL;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

function Challenge() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { doorId, storeId } = useParams();
  const [challenge, setChallenge] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);

  React.useState(false);

  const getChallengeById = React.useCallback(async () => {
    setFetching(true);
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${AUTH_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });

      const { data } = await axios.get(`${API_URL}/doors/${doorId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (data) {
        setChallenge({
          ...data.door,
        });
      } else {
        setChallenge(null);
      }
    } catch (error) {
      console.log(error);
      setChallenge(null);
    }
    setFetching(false);
  }, [doorId, getAccessTokenSilently]);

  React.useEffect(() => {
    if (doorId) {
      getChallengeById();
    }
  }, [doorId, getChallengeById]);

  async function testUnlock(doorId) {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${AUTH_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });

      const { data } = await axios.get(`${API_URL}/unlock/${doorId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (data && data?.success === true) {
        alert("Dörren låstes upp korrekt");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      alert("Något gick fel när dörren skulle låsas upp");
      return false;
    }
  }

  return (
    isAuthenticated && (
      <div>
        <div className={styles.subHeader}>
          <Content>
            <Link className={styles.backButton} to={`/stores/${storeId}`}>
              <Arrow
                className={styles.backIcon}
                direction="left"
                color="#cf2e05"
              />
              Tillbaka
            </Link>
          </Content>
        </div>
        <section className={styles.challenge}>
          {fetching === true ? (
            <div className={styles.loading}>
              <Content>
                <p className={styles.loadingText}>Hämtar dörr</p>
              </Content>
            </div>
          ) : challenge ? (
            <Content>
              <div className={styles.content}>
                <div className={styles.challengeInfo}>
                  <p>Label: {challenge?.label}</p>
                  <p>DoorId: {challenge.door_id}</p>
                  <p>Url: {challenge.url}</p>

                  <button
                    className={styles.noChallengesLink}
                    onClick={() => testUnlock(doorId)}
                  >
                    Testa Dörröppning
                  </button>
                </div>
              </div>
            </Content>
          ) : (
            <div>
              <Content>
                <p>Kunde inte hitta någon dörr med id {doorId}</p>
                <Link to="/stores/">Tillbaka till listan</Link>
              </Content>
            </div>
          )}
        </section>
      </div>
    )
  );
}
export default Challenge;
