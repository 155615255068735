import React from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Content from "../components/layout/Content";
import styles from "../styles/Challenges.module.css";
import Arrow from "../icons/Arrow";

const API_URL = process.env.REACT_APP_API_BASEURL;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

function Challenges() {
  const { storeId } = useParams();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [challenges, setChallenges] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    let componentMounted = true;

    async function getChallenges() {
      setFetching(true);

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });

        if (accessToken) {
          const { data } = await axios.get(
            `${API_URL}/stores/${storeId}/doors`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (data && data.doors?.length > 0 && componentMounted) {
            setChallenges(data.doors);
          } else {
            setChallenges([]);
          }
        }
      } catch (error) {
        console.log(error);
        setChallenges([]);
      }
      setFetching(false);
    }

    getChallenges();

    return () => {
      componentMounted = false;
    };
  }, [getAccessTokenSilently, storeId]);

  return (
    isAuthenticated && (
      <div>
        <div className={styles.subHeader}>
          <Link className={styles.backButton} to={`/stores`}>
            <Arrow
              className={styles.backIcon}
              direction="left"
              color="#cf2e05"
            />
            Tillbaka
          </Link>
          <Link className={styles.headerButton} to={`/stores/${storeId}/door`}>
            Skapa ny dörr
          </Link>
        </div>
        <section className={styles.challenges}>
          {fetching === true ? (
            <div className={styles.loading}>
              <Content>
                <p className={styles.loadingText}>Hämtar tävlingar…</p>
              </Content>
            </div>
          ) : challenges.length > 0 ? (
            <Content>
              <h1 className={styles.heading}>Dörrar</h1>
              <ul className={styles.list}>
                {challenges.map((door, index) => (
                  <li className={styles.item} key={index}>
                    <Link
                      className={styles.link}
                      to={`/stores/${storeId}/doors/${door.id}`}
                    >
                      <span>{door.label}</span>
                      <span className={styles.itemPeriod}>{door.door_id}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </Content>
          ) : (
            <div className={styles.noChallenges}>
              <Content>
                <p className={styles.noChallengesText}>Skapa din första dörr</p>
                <Link
                  className={styles.noChallengesLink}
                  to={`/stores/${storeId}/door`}
                >
                  Ny dörr
                </Link>
              </Content>
            </div>
          )}
        </section>
      </div>
    )
  );
}
export default Challenges;
