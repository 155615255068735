import styles from '../../styles/Content.module.css'

function Content({ children, ...rest }) {

  return (
    <div {...rest} className={styles.content}>
      {children}
    </div>
  )
}

export default Content