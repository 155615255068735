import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Content from "../components/layout/Content";
import Input from "../components/Input";
import styles from "../styles/CreateTeam.module.css";
import Arrow from "../icons/Arrow";

const API_URL = process.env.REACT_APP_API_BASEURL;

function CreateDoor() {
  const { isAuthenticated } = useAuth0();
  const { storeId } = useParams();
  const navigate = useNavigate();

  const [doorId, setDoorId] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [label, setLabel] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(`${API_URL}/stores/${storeId}/doors`, {
        doorId,
        url,
        label,
      });
      if (data && data.hasOwnProperty("id")) {
        console.log(`Team with id: ${data.id} was successfully crerated`);
        if (isAuthenticated) {
          navigate("/stores");
        }
      } else {
        console.log("Something went wrong while creating team");
      }
    } catch (error) {
      console.log(error);
      console.log("Something went wrong while creating team");
    }

    setLoading(false);
  }

  return (
    <div>
      <div className={styles.subHeader}>
        <Content>
          <Link className={styles.backButton} to={`/stores/${storeId}`}>
            <Arrow
              className={styles.backIcon}
              direction="left"
              color="#cf2e05"
            />
            Tillbaka
          </Link>
        </Content>
      </div>
      <section className={styles.createTeam}>
        <Content>
          <h1 className={styles.heading}>Skapa Dörr</h1>
          <div className={styles.content}>
            <form onSubmit={onSubmit}>
              <div className={styles.formGroup}>
                <div>
                  <label className={styles.labelHeading} htmlFor="name">
                    Internt ID (Safeteam-dörr-id)
                  </label>
                  <Input
                    id="name"
                    type="text"
                    placeholder="Ange dörrId"
                    value={doorId}
                    onChange={(e) => setDoorId(e.target.value)}
                  />
                </div>

                <div>
                  <label className={styles.labelHeading} htmlFor="name">
                    URL
                  </label>
                  <Input
                    id="name"
                    type="text"
                    placeholder="Ange url"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>

                <div>
                  <label className={styles.labelHeading} htmlFor="name">
                    Beskrivning
                  </label>
                  <Input
                    id="name"
                    type="text"
                    placeholder="Ange beskrivning"
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                  />
                </div>
              </div>

              <div className={styles.action}>
                <button
                  className={styles.createButton}
                  disabled={loading}
                  type="submit"
                >
                  Skapa Dörr
                </button>
              </div>
            </form>
          </div>
        </Content>
      </section>
    </div>
  );
}
export default CreateDoor;
