import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Stores from "../pages/Stores";
import Doors from "../pages/Doors";
import CreateDoor from "../pages/CreateDoor";

import CreateStore from "../pages/CreateStore";
import Login from "../pages/Login";
import DoorDetail from "../pages/DoorDetail";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/stores" element={<Stores />} />
        <Route exact path="/stores/create" element={<CreateStore />} />
        <Route exact path="/stores/:storeId" element={<Doors />} />
        <Route exact path="/stores/:storeId/door" element={<CreateDoor />} />
        <Route
          exact
          path="/stores/:storeId/doors/:doorId"
          element={<DoorDetail />}
        />

        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
