import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/Login.module.css";

function Login() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate("/stores");
      }
    }
  }, [isLoading, isAuthenticated, navigate]);

  return isLoading ? (
    <div className={styles.page}>
      <p className={styles.loadingText}>Laddar…</p>
    </div>
  ) : !isAuthenticated ? (
    <div className={styles.loginPage}>
      <div className={styles.content}>
        <h1 className={styles.heading}>Admin</h1>
        <p className={styles.preamble}>
          Logga in för att administrera dörrar.
          <br />
          Saknar du login, maila doors@appuniverse.com
        </p>
        <button
          className={styles.loginButton}
          onClick={() => loginWithRedirect()}
        >
          Logga in
        </button>
      </div>
    </div>
  ) : null;
}
export default Login;
